<!-- <app-header
  [headerInfo]='headerInfo'
  [headerClasses]='headerClasses'
  [kvkResults]='kvkResults'
  [ngClass]= "{
    'header-hide-logo' : hasRedirectUrl
    }"
></app-header> -->

<div class="share-request-bgr" [ngStyle]="{ 'background-color': projectColor }">
  <div
    class="organizational-details d-none"
    [ngStyle]="{ 'background-color': projectColor }"
  >
    <div class="position-relative">
      <!-- <h2 class="od-title">
        Information Request
      </h2> -->
      <div class="od-main-container">
        <img src="/assets/images/request_screening.svg" class="od-image" />
        <h4 class="od-subtitle">
          {{ "shareRequestUser.terms.title" | translate }}
        </h4>
        <p>
          {{ consentMessage }}
        </p>
        <div id="organization-info-wrapper">
          <p *ngIf="organizationDetails">
            {{ "shareRequestUser.terms.organizationDetails.title" | translate }}
          </p>
          <div *ngIf="organizationDetails" class="organization-info">
            <img
              *ngIf="organizationLogo"
              [src]="organizationLogo | safeUrl"
              class="organization-logo"
            />
            <div class="od-text-info">
              <div
                *ngIf="
                  organizationDetails && organizationDetails.registeredName
                "
                class="organization-name"
              >
                {{ organizationDetails.registeredName }}
              </div>
              <div
                *ngIf="
                  organizationDetails && organizationDetails.registrationNumber
                "
              >
                {{
                  "shareRequestUser.terms.organizationDetails.registrationNumberLabel"
                    | translate
                }}
                <span> {{ organizationDetails.registrationNumber }} </span>
              </div>
              <div
                *ngIf="
                  organizationDetails && organizationDetails.AddressCountry
                "
              >
                {{
                  "shareRequestUser.terms.organizationDetails.registrationCountryLabel"
                    | translate
                }}
                <span> {{ organizationDetails.AddressCountry }} </span>
              </div>
              <div *ngIf="organizationDetails && organizationDetails.website">
                {{
                  "shareRequestUser.terms.organizationDetails.registrationWebsiteLabel"
                    | translate
                }}
                <a
                  target="_blank"
                  [href]="organizationDetails.website | orgLinkFilter"
                  >{{ organizationDetails.website }}</a
                >
              </div>
            </div>
          </div>
          <p
            class="disclaimer-text"
            [innerHTML]="
              'shareRequestUser.terms.termsLink'
                | translate: { privacyLink: privacyLink }
            "
          ></p>
          <button
            class="form-button form-button-1"
            (click)="hideOrganizationalDetails()"
          >
            {{ "common.continue" | translate }}
          </button>
        </div>
      </div>
      <div>
        <app-language-switcher></app-language-switcher>
      </div>
      <div class="organizational-details-loading d-none">
        <img
          src="/assets/images/spinner-green.svg"
          width="66px"
          height="66px"
          class="my-auto mx-auto"
        />
      </div>
    </div>
  </div>

  <div
    *ngIf="showClaimButton && !isAutomaticRequest"
    style="margin-bottom: 24px; margin-left: 30px; margin-right: 30px"
    class="d-flex justify-content-center align-items-center mt-5"
  >
    <div
      class="disclaimer-wrapper mb-1 d-flex align-items-center flex-column flex-md-row orange-border"
    >
      <div class="content">
        <h3>
          {{
            !returnedError
              ? ("shareRequestUser.claimNotification.title" | translate)
              : ("shareRequestUser.claimNotification.titleNotGranted"
                | translate)
          }}
        </h3>
        {{
          !returnedError
            ? ("shareRequestUser.claimNotification.content" | translate)
            : ("shareRequestUser.claimNotification.contentNotGranted"
              | translate)
        }}
      </div>
      <div *ngIf="!returnedError" class="d-flex flex-column buttons pl-4">
        <div class="d-flex justify-content-center mx-auto flex-column my-3">
          <button
            *ngIf="!existingProjects || !allowReuse"
            (click)="onClaimData()"
            class="form-button form-button-1 mr-auto"
            id="claimData"
          >
            {{ "shareRequestUser.claimNotification.acceptButton" | translate }}
          </button>
          <button
            *ngIf="existingProjects && allowReuse && !investorProjectToMergeTo"
            (click)="showClaimScreen()"
            class="form-button form-button-1"
            id="claimData"
          >
            {{ "shareRequestUser.claimNotification.acceptButton" | translate }}
          </button>
          <button
            *ngIf="investorProjectToMergeTo"
            (click)="onClaimData(true)"
            class="form-button form-button-1 mr-auto"
            id="claimData"
          >
            {{ "shareRequestUser.claimNotification.acceptButton" | translate }}
          </button>
          <button
            *ngIf="false"
            (click)="onRejectData()"
            class="form-button form-button-6"
            id="rejectData"
          >
            {{ "shareRequestUser.claimNotification.ignoreButton" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-content-builder
    class="d-none c-builder-container"
    [contentDOM]="groups['legalRepresentatives']"
    (contentReturn)="parseContentReturn($event)"
  ></app-content-builder>

  <div class="claim-form d-none">
    <div class="expire-lightbox form-container">
      <div class="overlay"></div>
      <div class="form-content-container">
        <h1 _ngcontent-c4="" class="form-type-heading position-relative">
          {{ "shareRequestUser.projectSelection.title" | translate }}
          <a
            *ngIf="!isAutomaticRequest"
            (click)="hideClaimScreen()"
            class="close-button"
          >
            <img src="assets/images/close_form_icon.png" />
          </a>
        </h1>
        <div class="position-relative">
          <div class="loading-icon d-none">
            <img
              src="/assets/images/spinner-green.svg"
              width="20px"
              height="20px"
              class="my-auto mx-auto"
            />
          </div>
          <div>
            <h4>
              {{ "shareRequestUser.projectSelection.content1" | translate }}
            </h4>
            <div class="mb-3">
              <div class="d-flex flex-row align-items-start notification info">
                <img
                  src="assets/images/icon-information-prepared-teal.svg"
                  width="16px"
                />
                <div class="d-flex flex-column pl-3 content">
                  <div class="description">
                    {{
                      "shareRequestUser.projectSelection.importantNote"
                        | translate
                    }}
                  </div>
                </div>
              </div>
            </div>
            <span class="input-label">
              {{ "shareRequestUser.projectSelection.content2" | translate }}
            </span>
            <!-- Radio Group -->
            <div>
              <input
                checked
                class="custom-radio-button"
                type="radio"
                id="existing_project"
                value="existing_project"
                name="claim_data"
              />
              <label class="custom-radio-label" for="existing_project">
                {{ "shareRequestUser.projectSelection.content3" | translate }}
              </label>
            </div>
            <div class="project-list mt-2">
              <span class="select-wrapper">
                <select class="select-input" id="project_list_id">
                  <option
                    value="{{ project.key }}"
                    *ngFor="let project of existingProjectList"
                  >
                    {{ project.record.name }}
                  </option>
                </select>
              </span>
            </div>
            <div>
              <input
                class="custom-radio-button"
                type="radio"
                id="new_project"
                name="claim_data"
              />
              <label class="custom-radio-label" for="new_project">
                {{ "shareRequestUser.projectSelection.content4" | translate }}
              </label>
            </div>
            <div class="entity-error-message d-none mt-2">
              {{ "shareRequestUser.projectSelection.content5" | translate }}
            </div>
            <button
              id="claimData2"
              (click)="onClaimData(true)"
              class="form-button form-button-1 mt-4 float-right"
            >
              {{ "common.continue" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="d-flex flex-column"
    style="margin-top: 100px"
    *ngIf="showGeneralLoadingSpinner"
  >
    <img
      src="/assets/images/spinner-green.svg"
      width="66px"
      height="66px"
      class="my-auto mx-auto"
    />
    <div
      class="px-3"
      style="
        font-size: 22px;
        font-weight: 700;
        margin-top: 30px;
        text-align: center;
      "
    >
      {{ "common.pleaseWait" | translate }}
    </div>
  </div>

  <div>
    <app-language-switcher></app-language-switcher>
  </div>

  <app-footer></app-footer>
</div>
