<div class="mb-0 form-container-side p-4" [ngClass]="{'related-party' : isRP}">
    <div class="d-flex justify-content-center pb-2">
        <img height="120" src="/assets/images/sign-contract.svg" class="result-image request-share-img" />
    </div>
    <h4 class="verify-heading my-3" [innerHTML]="
        'review.digitalVerifications.signOnBehalfTitle'
          | translate: { entity: entityName ? entityName : 'entity' }
      "></h4>
    <p class="pr-2" *ngIf="profile.typeOfInvestor === 'LegalPerson'" [innerHTML]="
        'review.digitalVerifications.content6'
          | translate: { entity: entityName ? entityName : 'entity' }
      "></p>
    <p class="mt-3">
        {{!isRP? ("review.digitalVerifications.signOnBehalfDescription" | translate) : ("review.digitalVerifications.signOnBehalfDescriptionRP" | translate)}}
    </p>

    <!--Strat of block for adding collaborators -->
    <div class="form-clarification custom-collapse my-4 p-3" *ngIf="!this.contractStep?.publicVerificationData?.hideCollaborator && !isSigned">
        <div href="#addCollaborators" data-toggle="collapse" role="button" aria-expanded="true"
            aria-controls="addCollaborators" class="d-flex collapse-header">
            <h2 class="form-clarification-title mb-0">
                {{
                "review.digitalVerifications.addCollaboratorsTitle" | translate
                }}
            </h2>
            <img src="assets/images/arrow-up-teal.svg" width="20px" height="20px" class="icon-tooltip dropdown" />
        </div>
        <div class="collapse clarification-content" id="addCollaborators">
            <div class="document-field-wrapper">
                <p class="mt-3">
                    {{
                    "review.digitalVerifications.addCollaboratorsDescription"| translate
                    }}
                </p>

                <div class="evidence-fields w-100">
                    <div class="element-wrapper mt-1">
                        <span class="input-label font-weight-bold">{{
                            "review.digitalVerifications.emailAddressCollaborator"
                            | translate
                            }}<sup class="sup-required">*</sup></span>
                        <!-- Input Text -->
                        <input type="text" value="" class="text-input" id="ContractEmail" name="ContractEmail"
                            [attr.isValid]="true" (keydown.enter)="$event.preventDefault()"
                            (keyup.enter)="addNewCollaborator($event)" (input)="newCollaborator()"
                            [attr.isRequired]="false" />
                        <div class="error-field contract-error-field d-none mt-0 bg-transparent">
                            {{ '("validation.validation5" | translate)' }}
                        </div>
                    </div>
                </div>
                <button (click)="addNewCollaborator()" id="contractSubmitButton"
                    class="form-button form-button-1 contract-button contract-button-contr">
                    <span>{{
                        "review.digitalVerifications.inviteCollaboratorBtn" | translate
                        }}</span>
                    <img width="25" src="/assets/images/spinner-gray.svg" />
                </button>
                <span class="invite-sent">{{
                    "review.digitalVerifications.InviteSent" | translate
                    }}</span>
                <small class="grey-font">{{
                    "review.digitalVerifications.noteOneActiveCollaboratorAtGivenMoment"
                    | translate
                    }}</small>
            </div>
        </div>
    </div>
    <!--  End of block for adding collaborators -->

    <!--Start of block for adding co-signers -->
    <div class="mb-3 contract-container" *ngIf="!this.contractStep?.publicVerificationData?.hideCosigner">
        <div class="form-clarification custom-collapse my-4 p-3">
            <div href="#addCoSigners" data-toggle="collapse" role="button" aria-expanded="true"
                aria-controls="addCoSigners" class="d-flex collapse-header">
                <h2 class="form-clarification-title mb-0" [innerHTML]="
                'review.digitalVerifications.addCoSignerTitle'
                  | translate: { entity: entityName ? entityName : 'entity' }
              "></h2>
                <img src="assets/images/arrow-up-teal.svg" width="20px" height="20px" class="icon-tooltip dropdown" />
            </div>
            <div class="collapse clarification-content" id="addCoSigners">
                <div class="document-field-wrapper">
                    <p class="mt-3">
                        {{
                        "review.digitalVerifications.addCoSignerDescription" | translate
                        }}
                    </p>

                    <div class="evidence-fields w-100">
                        <div class="element-wrapper mt-1">
                            <span class="input-label font-weight-bold">{{
                                "review.digitalVerifications.addCoSignerEmail" | translate
                                }}<sup class="sup-required">*</sup></span>
                            <input type="text" (keydown.enter)="$event.preventDefault()"
                                (keyup.enter)="submitCosigner($event)" (input)="deleteValidation()" value=""
                                class="text-input" id="ContractEmailSigner" name="ContractEmailSigner"
                                [attr.isValid]="true" [attr.isRequired]="false" />
                            <div class="error-field contract-signer-error-field d-none mt-0"></div>
                        </div>
                    </div>
                    <button (click)="submitCosigner($event)" id="contractSubmitButtonSigner"
                        class="form-button form-button-1 contract-button">
                        <span>{{
                            "review.digitalVerifications.addCoSignerBtn" | translate
                            }}</span>
                        <img width="25" src="/assets/images/spinner-gray.svg" />
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!--  End of block for adding co-signers -->

    <!--Start of block for displaying table of co-signers -->
    <div *ngIf="cosigners && cosigners.length > 0" class="custom-table">
        <p class="mb-0" style="font-weight: 700; color: #1a93a5">
            {{ "review.digitalVerifications.currentCoSigners" | translate }}
        </p>
        <table>
            <thead>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </thead>
            <tr *ngFor="let email of cosigners; let i = index">
                <td>{{ email.email }}</td>
                <td>
                    {{email.isSigned === true ? "Signed" : "Not signed"}}
                </td>
                <td>
                    <a *ngIf="!email.isSigned && !email.cannotDelete" href="#" [id]="'delete-co-signer' + i" class="delete-rp-icon d-flex"
                        (click)="removeCosigner($event, email.email, i)">
                        <img class="icon-normal" src="assets/images/delete-document.svg" width="16px" />
                        <img class="icon-red" src="assets/images/delete-document-red.svg" width="16px" />
                        <img class="spinner" src="/assets/images/spinner-green.svg" width="16px" />
                    </a>
                </td>
            </tr>
        </table>
    </div>
    <!--End of block for displaying table of co-signers  -->

    <div class="d-flex justify-content-end mt-5" *ngIf="!isSigned">
        <button (click)="redirectInvestorToDocument()" id="continueToDocument" class="form-button form-button-1">
            <span>{{
                "review.digitalVerifications.continueToTheDocumentBtn" | translate
                }}</span>
            <img width="25" src="/assets/images/spinner-gray.svg" />
        </button>
    </div>
</div>

<div 
*ngIf="displaySmartForm"
style="
position: absolute;
top: 0;
left: 0;
z-index: 5000;">
<app-contract-verification-onboard-subject
[outerVerificationKey]="outerVerificationKey"
(hideContractComponent) = "hideContractComponent(event)">

</app-contract-verification-onboard-subject>
</div>
