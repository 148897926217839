import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HelperServiceService } from "src/app/helper-service.service";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { ElementsService } from "../elements.service";
import { commentsOnWhitelistingHit } from "src/app/id-verification/id-verification-const";
import { UiService } from "src/app/ui/ui.service";
import { UserService } from "src/app/user.service";

@Component({
  selector: "app-preview-window-cdd-sharing",
  templateUrl: "./preview-window-cdd-sharing.component.html",
  styleUrls: ["./preview-window-cdd-sharing.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PreviewWindowCddSharingComponent implements OnInit, OnDestroy {
  @Input() element;
  @Output() contentReturn = new EventEmitter<any>();
  @Output() displayLoader = new EventEmitter<any>();
  reasonDialogObs;
  loadingPrefillSpinner: boolean = false;
  commentsOnWhitelistingHit: string[];

  reasonDialogueType; // what mode is the "reason for action" dialogue set too, the default is expiry

  constructor(
    public elementService: ElementsService,
    public idVerificationService: IdVerificationService,
    private helperService: HelperServiceService,
    public translate: TranslateService,
    public uiService: UiService,
    public userService: UserService,
  ) {}

  ngOnInit(): void {


    this.reasonDialogueType = "expiry";
    this.reasonDialogObs =
      this.idVerificationService.detectPreviewWindowPopUp.subscribe(
        (status) => {
          $(".expire-lightbox .loading-icon").addClass("d-none");
          this.reasonDialogueType = status;
        },
      );
    if (this.element.params.apsData) {
      this.idVerificationService.setAPSSTatusses(this.element.params.statuses);
      this.idVerificationService.setAPSFields(this.element.params.fieldTypes);
      this.idVerificationService.sortingHitsOnComplianceCheck();
      this.idVerificationService.calculatingWhitelistedHits();
    }
    this.commentsOnWhitelistingHit = commentsOnWhitelistingHit;
    if(this.element?.params?.documentKeys?.length != this.element?.params?.evidence?.documents?.length)
    {
      this.generateDocuments();
    }
  }

  generateDocuments()
  {
    this.element.params.documentKeys = this.element.params.evidence.documents
    if(this.element.params.documentKeys.length > 0)
    {
      this.element.params.noDocumentsDownload = false;
      this.element.params.noDocumentsDisplay = false;

    }
  }
  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onDisplayLoader(emitPayload: boolean) {
    if (emitPayload) {
      this.displayLoader.emit(emitPayload);
    }
  }

  ngOnDestroy() {
    this.reasonDialogObs.unsubscribe();
    $(".downloadable-iframe-preview").each((index, iframe) => {
      window.URL.revokeObjectURL(iframe.getAttribute("src"));
    });
  }
  async onAcceptEVDConfirm(event) {
    let requestKey = this.idVerificationService.getActiveInvestor();
    if(event.target) {
      requestKey = $(event.target).attr('requestKey')
    }
    console.log($(event.target).attr('requestKey'));
    $("#evidenceAcceptDocument").addClass("download-loading");
    await this.idVerificationService.acceptOrRejectDV(
      this.idVerificationService.getActiveProject(),
      requestKey,
      this.element.params.evidenceKey,
      true,
      true,
    );
  }
  async onRejectEVDConfirm(event) {
    let requestKey = this.idVerificationService.getActiveInvestor();
    if(event.target) {
      requestKey = $(event.target).attr('requestKey')
    }
    $("#evidenceRejectDocument").addClass("download-loading");
    await this.idVerificationService.acceptOrRejectDV(
      this.idVerificationService.getActiveProject(),
      requestKey,
      this.element.params.evidenceKey,
      false,
      true,
    );
  }
   disablePV() : boolean
  {
    return !(this.idVerificationService.permissionProfileVerification() && this.element.params.enabledProfileVerification)
  }



  getTooltip() : string
  {
    if(this.element?.params?.fieldTypes)
    {
      let activeInvestor = this.idVerificationService.getActiveInvestorDetaisl();
      if(this.element?.params?.requestKey && this.helperService.isRPKey(this.element.params.requestKey))
      {
        activeInvestor = this.idVerificationService.getActiveInvestorDetaisl()["activeRelatedParty"]["record"];
      }
      if(this.disablePV())
      {
        return this.idVerificationService.getPVTooltip(activeInvestor);
      }
      else{
        return '';
      }
    }
    else{
      this.idVerificationService.getPVTooltip(undefined)
    }
  }
  preFillSmartForm()
  {
    let paylod =  {'email': this.userService.getUserInfo()["username"]}
    this.userService.postEndPoint(
      this.idVerificationService.buildEndPoint(
        "digitalVerificationReview",
        this.idVerificationService.getActiveProject(),
        this.idVerificationService.getActiveInvestor(),
        "",
        true,
        this.element.params.evidenceKey,
      ),
      paylod,
      { responseType: "json" },
      { observe: "body" },
      "funds",
    ).subscribe( (response) => {
      if (response === undefined) {
        console.error(
          "Unexpected error as we were writing the risk information",
        );
        return;
      }
      if(response['verificationLink'])
      {
        window.open(response['verificationLink'], '_blank').focus();
      }
      this.loadingPrefillSpinner = false;
    });
  }
}
