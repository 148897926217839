import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { UserService } from "src/app/user.service";
import { ElementsService } from "../elements.service";
import { AR_fill_RP_allowed_contry_list } from "../../../../../id-verification/id-verification-const";

@Component({
  selector: "app-kompany-seach",
  templateUrl: "./kompany-seach.component.html",
  styleUrls: ["./kompany-seach.component.scss"],
})
export class KompanySeachComponent implements OnInit {
  @Input() element;
  @Input() currentlySelectedCountry;
  @Output() contentReturn = new EventEmitter<any>();
  isoCountryList;
  AR_fill_RP_allowed_contry_list = AR_fill_RP_allowed_contry_list;
  company;
  pleaseWaitMore:boolean = false;

  constructor(
    public elementService: ElementsService,
    private idVerificationService: IdVerificationService,
    private userService: UserService,
    public translate: TranslateService,
  ) {}

  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

  ngOnInit(): void {
    this.isoCountryList = this.idVerificationService.getCountryListAVClean();
    if (this.currentlySelectedCountry) {
      this.idVerificationService.localFundCreationData.selectedCountryValue =
        this.currentlySelectedCountry;
      this.element.params.selectedCountryValue = this.currentlySelectedCountry;
    }
  }
  isInvestor(): boolean
  {
    return this.idVerificationService.isInvestorType();
  }

  selectCountryKompany(event) {
    const selectedValue = $("#CountryOfRegistration option:selected").val();
    if (selectedValue !== "emptySelect") {
      if (!this.idVerificationService.localFundCreationData.investorFields) {
        this.idVerificationService.localFundCreationData.investorFields = {};
        this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration =
          this.getBECountryType(selectedValue);
        this.idVerificationService.localFundCreationData.investorFields.RegisteredAddressCountry =
          this.getBECountryType(selectedValue);
      } else {
        this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration =
          this.getBECountryType(selectedValue);
        this.idVerificationService.localFundCreationData.investorFields.RegisteredAddressCountry =
          this.getBECountryType(selectedValue);
      }
      $(".kompany-search-input").removeClass("search-blurred");
    } else {
      $(".kompany-search-input").addClass("search-blurred");
    }
  }

  unParseCountryName(name: string) {
    name = name.replace(/ /g, "-");
    return name;
  }

  unParseCountryNameII(name: string) {
    name = name.replace(/ /g, "_");
    return name;
  }

  // get the BE country type from the type of kompany country selected
  getBECountryType(kompanyCountry) {
    if (kompanyCountry.includes("US-")) {
      return "United_States_of_America";
    } else if (kompanyCountry.includes("RU")) {
      return "Russian_Federation";
    } else if (kompanyCountry === "UK") {
      return "United_Kingdom_of_Great_Britain_and_Northern_Ireland";
    } else {
      return this.unParseCountryNameII(
        this.isoCountryList.find((country) => country.value === kompanyCountry)
          .label,
      );
    }
  }
  kompanySearch(event) {
    event.preventDefault();
    const isAdvancedSearch =
      this.idVerificationService.localFundCreationData.advancedSearch;
    if (isAdvancedSearch) {
      if (
        $("#kompanyInputAddress").val().toString().trim() === "" &&
        $("#kompanyInputPhone").val().toString().trim() === "" &&
        $("#kompanyInputVat").val().toString().trim() === ""
      ) {
        $(".kompany-search-error-field").addClass("d-flex");
        return;
      }
    } else {
      if ($("#kompanyInput").val().toString().trim() === "") {
        $(".kompany-search-error-field").addClass("d-flex");
        return;
      }
    }
    const selectedCountry = $("#CountryOfRegistration option:selected")
      .text()
      .trim();
    const selectedCountryValue = $(
      "#CountryOfRegistration option:selected",
    ).val();
    const selectedCountryCode = selectedCountryValue;
    let payload;
    payload = {};
    payload.country = selectedCountryCode;
    this.idVerificationService.localFundCreationData.selectedCountryValue =
      selectedCountryValue;
    if ($("#kompanySearchName").is(":checked") && !isAdvancedSearch) {
      if ($("#kompanyInput").val() !== "") {
        payload.name = $("#kompanyInput").val();
      }
    } else if ($("#kompanySearchID").is(":checked") && !isAdvancedSearch) {
      if ($("#kompanyInput").val() !== "") {
        payload.number = $("#kompanyInput").val();
      }
    } else {
      if ($("#kompanyInput").val() !== "" && !isAdvancedSearch) {
        payload.lei = $("#kompanyInput").val();
      }
    }
    if (
      $("#kompanyInputAddress").val().toString().trim() !== "" &&
      isAdvancedSearch
    ) {
      payload.address = $("#kompanyInputAddress").val();
    }
    if (
      $("#kompanyInputPhone").val().toString().trim() !== "" &&
      isAdvancedSearch
    ) {
      payload.phone = $("#kompanyInputPhone").val();
    }
    if (
      $("#kompanyInputVat").val().toString().trim() !== "" &&
      isAdvancedSearch
    ) {
      payload.vat = $("#kompanyInputVat").val();
    }
    $(".kompany-loading").removeClass("d-none");
    $(".kompany-search-input").addClass("loading");
    this.element.params.loadingMode = true;

    if (payload.lei) {
      this.getKompanyDetails(undefined, payload);
    } else {
      this.kompanySearchCall(payload);
    }
  }

  kompanySearchCall(payload) {
    this.userService
      .postEndPoint(
        this.idVerificationService.buildEndPoint("kompanySearch"),
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (typeof response === undefined) {
            console.log(
              "There was an unexpected error while posting a new project",
            );
            return;
          }
          this.element.params.processStart = false;
          this.idVerificationService.localFundCreationData.fetchedCompanyList =
          response[0].searchResults;
          this.idVerificationService.localFundCreationData.fetchedCompanyCountry =
          payload.country;
          if (
            this.idVerificationService.localFundCreationData.fetchedCompanyList
              .length === 0
          ) {
            this.idVerificationService.localFundCreationData.fetchedCompanyCountry = undefined;
            this.element.params.kompanyCallError = true;
            this.element.params.errorMessage = this.translate.instant(
              "kompanySearch.error",
            );
          } else {
            this.element.params.fetchedCompanyList =
              this.idVerificationService.localFundCreationData.fetchedCompanyList;
            if (!this.element.params.reviewProcess) {
              this.element.class = {};
            }
          }
          this.element.params.loadingMode = false;
        },
        (error) => {
          // there was an error with the kompany search
          // redirecting to manual search
          this.element.params.processStart = false;
          this.idVerificationService.localFundCreationData.kompanyCallError =
            true;
          this.element.params.kompanyCallError = true;
          this.element.params.errorMessage = this.translate.instant(
            "kompanySearch.error2",
          );
          this.element.params.loadingMode = false;
        },
      );
  }

  cancelBasicFieldsProcess() {
    this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration =
      undefined;
    this.idVerificationService.localFundCreationData.fetchedCompanyList =
      undefined;
    this.idVerificationService.localFundCreationData.kompanyCallError = false;
    this.element.params.processStart = true;
    this.element.params.kompanyCallError = false;
    this.element.params.selectedCountryValue = "emptySelect";
    this.idVerificationService.basicFieldsCompanySearch.next("cancel");
  }

  searchKompanyAgain(event) {
    this.idVerificationService.localFundCreationData.fetchedCompanyList =
      undefined;
    this.idVerificationService.localFundCreationData.kompanyCallError = false;
    this.element.params.fetchedCompanyList = undefined;
    this.element.params.processStart = true;
    this.element.params.kompanyCallError = false;
    this.element.params.dontBlur = true;
  }

  selectKompanyFromList(event, company) {
    $(".kompany-confirmation-dialogue").removeClass("d-none");
    this.company = company
    $(event.target).addClass("selected");
    $(".kompany-confirmation-dialogue-name").text(this.company.name);
    $(".search-kompany-again").addClass("d-none");
  }

  selectKompanyFromListConfirm(event) {
    this.element.params.loadingMode = true;
    this.getCompanyDataset();
  }

  selectKompanyFromListCancel() {
    $(".kompany-confirmation-dialogue").addClass("d-none");
    $(".search-kompany-again").removeClass("d-none");
    $(".kompany-list-member.selected").removeClass("selected");
  }

  showOptionalParameters() {
    $(".show-optional-parameters-button").addClass("d-none");
    $(".hide-optional-parameters-button").removeClass("d-none");
    $(".optional-parameters").removeClass("d-none");
    $(".company-input-search-outer").removeClass("d-none");
    $(".company-input-search-inner").addClass("d-none");
    $(".kompany-main-input-wrapper").removeClass("kompany-main-input-wrapper");
    $(".kompany-search-error-field").removeClass("d-flex");
    $(".basic-search-container").addClass("search-blurred");
    this.idVerificationService.localFundCreationData.advancedSearch = true;
  }

  hideOptionalParameters() {
    $(".show-optional-parameters-button").removeClass("d-none");
    $(".hide-optional-parameters-button").addClass("d-none");
    $(".company-input-search-outer").addClass("d-none");
    $(".company-input-search-inner").removeClass("d-none");
    $(".optional-parameters").addClass("d-none");
    $(".kompany-search-error-field").removeClass("d-flex");
    $(".basic-search-container").removeClass("search-blurred");
    this.idVerificationService.localFundCreationData.advancedSearch = false;
  }


  splitFirstAndLastNameARRP(person)
  {
    let FName : string = '';
    let LName : string = '';
    if(person?.name)
    {
      let name = person.name.split(',')
      if(name?.length > 1)
      {
        let index = Math.floor(name.length/2);
        FName = name.slice(index, name.length).join(' ');
        LName = name.slice(0,index).join(' ');
      }
      else
      {
        name = person.name.split(/\s+/);
        if(name?.length > 1)
          {
            let index = Math.floor(name.length/2);
            FName = name.slice(0,index).join(' ');
            LName = name.slice(index, name.length).join(' ');
          }

      }
    }
    return [FName, LName];

  }

  parseKompanyPeople(people) {
    let parsedPeople = {};
    let owners = people.ultimateBeneficialOwners;

    if(!people) {
      people = [];
    }
    if(!owners) {
      owners = [];
    }

    parsedPeople['directors'] = [];
    parsedPeople['shareholders'] = [];
    parsedPeople['ubo'] = [];

    //directors
    people.director?.forEach(person => {
      if(person.status === 'CURRENT') {
        let [Fname, Lname] = this.splitFirstAndLastNameARRP(person);
        if(Fname && Lname)
        {
          parsedPeople['directors'].push({
            naturalFirstName: Fname,
            naturalLastName: this.capitalizeFirstLetter(Lname),
            naturalRole: "Director",
            relatedPartyTypeName: 'Control',
            type: 'natural'
          });
        }
      }
    });
    
       //shareholders
       people.shareholders?.forEach(person => {
        if(person.status === 'CURRENT') {
          let [Fname, Lname] = this.splitFirstAndLastNameARRP(person);
          if(Fname && Lname)
            {
              parsedPeople['shareholders'].push({
                naturalFirstName: Fname,
                naturalLastName: this.capitalizeFirstLetter(Lname),
                naturalRole: "Shareholder",
                relatedPartyTypeName: 'Ownership',
                type: 'natural'
              });
            }
        }
      });

    //directors
    owners?.forEach(owner => {
      if(owner.status === 'Active') {
          if(owner.isCorporate == "1" && owner?.name) {
            parsedPeople['shareholders'].push({
              legalName: owner.name,
              legalRole: "Shareholder",
              relatedPartyTypeName: 'Ownership',
              legalType: "Company",
              type: 'legal'
            });
          }
          if(owner.isCorporate == "0") {
          let [Fname, Lname] = this.splitFirstAndLastNameARRP(owner);
          if(Fname && Lname)
          {
            parsedPeople['ubo'].push({
              naturalFirstName: Fname,
              naturalLastName: this.capitalizeFirstLetter(Lname),
              naturalRole: "UltimateBeneficialOwner",
              relatedPartyTypeName: 'UBO',
              type: 'natural'
            });
          }
          }
      }
    });

    //beneficial owners
    return parsedPeople;
  }

capitalizeFirstLetter(string) {
    if(string) {
      string = string.toLowerCase();
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return undefined;
}
generatefetchedCompanyListDetails(response)
{
  let lastUpdate;
  let fetchedCompanyListDeatils={};
  if(response?.content?.sources?.length > 0)
    {
      lastUpdate = response.content.sources.reduce((latest, source) => {
        const sourceTime = new Date(source.lastCheckedTime);
        return sourceTime > latest ? sourceTime : latest;
      }, new Date(0));
    }
    lastUpdate = lastUpdate.getTime();
    if(response?.companyId)
    {
      Object.defineProperty(fetchedCompanyListDeatils, "id", {value:response.companyId});
    }
    if( response?.content?.countryCode)
    {
      Object.defineProperty(fetchedCompanyListDeatils, "country", {value: response.content.countryCode});
    }
    if(response?.content?.registrationNumber)
    {
      Object.defineProperty(fetchedCompanyListDeatils, "registrationNumber", {value: response.content.registrationNumber});
    }
    if(response?.content?.name)
    {
      Object.defineProperty(fetchedCompanyListDeatils, "name", {value: response.content.name});
    }
    if(response?.content?.status)
    {
      Object.defineProperty(fetchedCompanyListDeatils, "status", {value: response.content.status});
    }
    if(response?.content?.address)
    {
      Object.defineProperty(fetchedCompanyListDeatils, "address", {value: [response.content.address]});
    }
    if(response?.content?.additionalData?.contactInformation?.formattedAddress)
    {
      Object.defineProperty(fetchedCompanyListDeatils, "formattedAddress", {value: response.content.additionalData.contactInformation.formattedAddress});
    }     
    if(response?.requestTime)
    {
      Object.defineProperty(fetchedCompanyListDeatils, "requestTime", {value: response.requestTime});
    }
    if(lastUpdate)
    {
      Object.defineProperty(fetchedCompanyListDeatils, "lastUpdate", {value: lastUpdate});
    }
    this.idVerificationService.localFundCreationData.fetchedCompanyListDeatils = fetchedCompanyListDeatils;
}
failCompanySearch(error)
{
          this.element.params.loadingMode = false;
          this.pleaseWaitMore = false;
          this.idVerificationService.localFundCreationData.kompanyCallError =
            true;
          this.element.params.kompanyCallError = true;
          this.element.params.processStart = false;
          if (error.error === "No results found") {
            this.element.params.errorMessage = this.translate.instant(
              "kompanySearch.error",
            );
          }
        this.selectKompanyFromListCancel();

}

completedCompanySearch(response)
{
  this.generatefetchedCompanyListDetails(response);
  if(response?.content?.associatedEntities) {  
    this.idVerificationService.localFundCreationData
    .fetchedCompanyListDeatils.parsedRelatedParties =
    this.parseKompanyPeople(response.content.associatedEntities);
  }
  this.mapKompanyDetailsToFields(
    response,
    this.idVerificationService.localFundCreationData,
    this.element.params.reviewProcess,
  );
  this.idVerificationService.localFundCreationData.linkToStep = "manual";
  this.idVerificationService.localFundCreationData.unhideFields = true;
  this.element.params.loadingMode = false;
  this.pleaseWaitMore = false;
  if (this.element.params.reviewProcess) {
    const evidenceKey = this.element.params.evidenceKey;
    const evidenceTypes = this.element.params.evidenceTypes;
    const evidenceType =
      this.idVerificationService.returnEvidenceFieldByKey(
        evidenceKey,
        evidenceTypes,
      );
    evidenceType.fields = {};
    // evidenceType = undefined;
    const flowType = $(
      ".document-category-main.position-relative.category-active",
    ).attr("data-flowtype");
    const currentlyActiveEvidence = $(
      ".documentUploaderSide.evidence-is-active",
    ).attr("id");
    const currentlyActiveRequest = $(
      ".documentUploaderSide.evidence-is-active",
    ).attr("data-requestkey");
    const nextActiveRequest = $(
      '.document-category-main[data-EvidenceKey="' + evidenceKey + '"]',
    ).attr("data-requestkey");
    this.idVerificationService.saveFieldsState(
      currentlyActiveEvidence,
      evidenceTypes,
      undefined,
      undefined,
      undefined,
      true,
    );
    this.idVerificationService.setExpandedEvidence(evidenceKey);
    // this happens only when we're in the review flow and we use the search function
    // fieldsource will be available to the review / update flow at all other times
    // make sure the data from the fieldsSource is properly added to the investor data / evidence type data
    if (true) {
      this.idVerificationService.getActiveInvestorDetaisl().fieldsSource =
        [];
      this.idVerificationService
        .getActiveInvestorDetaisl()
        .fieldsSource.push(
          this.idVerificationService.localFundCreationData.fieldsSource,
        );
      this.idVerificationService.getActiveInvestorDetaisl().investorFields =
        this.idVerificationService.localFundCreationData.investorFields;
      evidenceType.fields =
        this.idVerificationService.localFundCreationData.investorFields;
    }
    this.idVerificationService.loadEvidenceFieldAndImages(
      this.idVerificationService.getActiveProject(),
      nextActiveRequest,
      evidenceKey,
      flowType,
      evidenceTypes,
      false,
      evidenceType?.RProle ? false : true, // only true if it is not an RP
    );
  } else if (this.element.params.basicFieldsProcess) {
    this.idVerificationService.basicFieldsCompanySearch.next("finish");
  } else {
    if (
      this.idVerificationService.localFundCreationData
        .relatedPartyCreation
    ) {
      this.idVerificationService.skipToStep(12.3);
    } else {
      const additionalData: any = {};
      additionalData.type = "LegalPerson";
      if ($("#automaticRequestTemplate").val() !== "") {
        additionalData.selectedTemplate = $(
          "#automaticRequestTemplate",
        ).val();
        additionalData.EmailAddress = $("#automaticRequestEmail").val();
        additionalData.allowInvestorEdit = $(
          "#allowInvestorProfileDetails",
        ).prop("checked");
      }
      this.idVerificationService.skipToStep(5.3, additionalData);
    }
  }
}

getProcessingData(count, response)
{
  this.pleaseWaitMore = true;
  setTimeout(() => {
    count-=1;
    this.userService.getEndPoint(
      this.idVerificationService.buildEndPoint("getCompanyDataset", response.datasetId),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    ).subscribe(
      (response) =>
      {
        if (typeof response === undefined) {
          console.log(
            "There was an unexpected error while posting a new project",
          );
          return;
        }
        else if(response["status"] == "PROCESSING" && count>0)
        {
          this.getProcessingData(count, response);
        }
        else if(response["status"] == "COMPLETED")
        {
          this.completedCompanySearch(response);
        }
        else 
        {
          let error ={
            error: "No results found"
          }
          this.failCompanySearch(error);
        }
  
      },
      (error) => {
  
        this.failCompanySearch(error);
      },
    );
  }, 15000); 
}
getCompanyDataset()
{
  if(this.company)
  {
    let payload = {
      id: this.company.companyId,
      country: this.company.countryCode
    }
    this.userService.postEndPoint(
      this.idVerificationService.buildEndPoint("postCompanyDataset"),
      payload,
      { responseType: "json" },
      { observe: "body" },
      "funds",
    ).subscribe(
      (response) =>
      {
        if (typeof response === undefined) {
          console.log(
            "There was an unexpected error while posting a new project",
          );
          return;
        }
        else if(response["status"] == "COMPLETED")
        {
          this.completedCompanySearch(response);
        }
        else if(response["status"] == "PROCESSING")
        {
          this.getProcessingData(5, response)
        }
        else 
        {
          let error ={
            error: "No results found"
          }
          this.failCompanySearch(error);
        }
  
      },
      (error) => {
  
        this.failCompanySearch(error);
      },
    );
  }

   
}

  getKompanyDetails(companyID, leiSearch?, beneficialOwnersData?, fullData?) {
    let endpoint = "kompanyDetails";
    let url = this.idVerificationService.buildEndPoint("kompanyDetails", companyID);
    if(beneficialOwnersData) {
      url = this.idVerificationService.buildEndPoint("kompanyDetails", companyID,'','?dataset=beneficial_owners');
    }
    if(fullData) {
      url = this.idVerificationService.buildEndPoint("kompanyDetails", companyID,'','?dataset=full');

    }
    let callVariable = this.userService.getEndPoint(
      url,
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
    if (leiSearch) {
      endpoint = "kompanySearch";
      callVariable = this.userService.postEndPoint(
        this.idVerificationService.buildEndPoint("kompanySearch"),
        leiSearch,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      );
    }
    callVariable.subscribe(
      (response) => {
        if (typeof response === undefined) {
          console.log(
            "There was an unexpected error while posting a new project",
          );
          return;
        }
        if (leiSearch) {
          response = response[0];
        }
        //parse the people if there are any

        this.idVerificationService.localFundCreationData.fetchedCompanyListDeatils =
          response;

          if(response['people']) {  
            this.idVerificationService.localFundCreationData
            .fetchedCompanyListDeatils.parsedRelatedParties =
            this.parseKompanyPeople(response);
          }


        this.mapKompanyDetailsToFields(
          response,
          this.idVerificationService.localFundCreationData,
          this.element.params.reviewProcess,
        );
        this.idVerificationService.localFundCreationData.linkToStep = "manual";
        this.idVerificationService.localFundCreationData.unhideFields = true;
        this.element.params.loadingMode = false;
        if (this.element.params.reviewProcess) {
          const evidenceKey = this.element.params.evidenceKey;
          const evidenceTypes = this.element.params.evidenceTypes;
          const evidenceType =
            this.idVerificationService.returnEvidenceFieldByKey(
              evidenceKey,
              evidenceTypes,
            );
          evidenceType.fields = {};
          // evidenceType = undefined;
          const flowType = $(
            ".document-category-main.position-relative.category-active",
          ).attr("data-flowtype");
          const currentlyActiveEvidence = $(
            ".documentUploaderSide.evidence-is-active",
          ).attr("id");
          const currentlyActiveRequest = $(
            ".documentUploaderSide.evidence-is-active",
          ).attr("data-requestkey");
          const nextActiveRequest = $(
            '.document-category-main[data-EvidenceKey="' + evidenceKey + '"]',
          ).attr("data-requestkey");
          this.idVerificationService.saveFieldsState(
            currentlyActiveEvidence,
            evidenceTypes,
            undefined,
            undefined,
            undefined,
            true,
          );
          this.idVerificationService.setExpandedEvidence(evidenceKey);
          // this happens only when we're in the review flow and we use the search function
          // fieldsource will be available to the review / update flow at all other times
          // make sure the data from the fieldsSource is properly added to the investor data / evidence type data
          if (true) {
            this.idVerificationService.getActiveInvestorDetaisl().fieldsSource =
              [];
            this.idVerificationService
              .getActiveInvestorDetaisl()
              .fieldsSource.push(
                this.idVerificationService.localFundCreationData.fieldsSource,
              );
            this.idVerificationService.getActiveInvestorDetaisl().investorFields =
              this.idVerificationService.localFundCreationData.investorFields;
            evidenceType.fields =
              this.idVerificationService.localFundCreationData.investorFields;
          }
          this.idVerificationService.loadEvidenceFieldAndImages(
            this.idVerificationService.getActiveProject(),
            nextActiveRequest,
            evidenceKey,
            flowType,
            evidenceTypes,
            false,
            evidenceType?.RProle ? false : true, // only true if it is not an RP
          );
        } else if (this.element.params.basicFieldsProcess) {
          this.idVerificationService.basicFieldsCompanySearch.next("finish");
        } else {
          if (
            this.idVerificationService.localFundCreationData
              .relatedPartyCreation
          ) {
            this.idVerificationService.skipToStep(12.3);
          } else {
            const additionalData: any = {};
            additionalData.type = "LegalPerson";
            if ($("#automaticRequestTemplate").val() !== "") {
              additionalData.selectedTemplate = $(
                "#automaticRequestTemplate",
              ).val();
              additionalData.EmailAddress = $("#automaticRequestEmail").val();
              additionalData.allowInvestorEdit = $(
                "#allowInvestorProfileDetails",
              ).prop("checked");
            }
            this.idVerificationService.skipToStep(5.3, additionalData);
          }
        }
      },
      (error) => {
        // there was an error with the kompany search
        // redirecting to manual search
        if((!beneficialOwnersData && !fullData) || fullData) {
          this.element.params.loadingMode = false;
          this.idVerificationService.localFundCreationData.kompanyCallError =
            true;
          this.element.params.kompanyCallError = true;
          this.element.params.processStart = false;
          if (error.error === "No results found") {
            this.element.params.errorMessage = this.translate.instant(
              "kompanySearch.error",
            );
          }
          if (this.element.params.basicFieldsProcess) {
            // console.log('todo');
          } else {
            // if (this.idVerificationService.localFundCreationData.relatedPartyCreation) {
            //   this.idVerificationService.skipToStep(12.1);
            //   } else {
            //   this.idVerificationService.skipToStep(5.2);
            //  }
          }
        }
        else {
         this.getKompanyDetails(companyID, leiSearch, false, true)
        }
      },
    );
  }

  /**
    @param data data to parse
    @param returnObject object to attach the parsed data to
    @param isReviewProcess if it's the element in the review process we also need to change the HTML
   */
  mapKompanyDetailsToFields(data, returnObject, isReviewProcess) {
    if (!returnObject.investorFields) {
      returnObject.investorFields = {};
    }
    // create the fieldSource object
    let fieldsSource;
    let lastUpdate;
    if(data?.content?.sources?.length > 0)
    {
      lastUpdate = data.content.sources.reduce((latest, source) => {
        const sourceTime = new Date(source.lastCheckedTime);
        return sourceTime > latest ? sourceTime : latest;
      }, new Date(0));
    }
    lastUpdate = lastUpdate.getTime();
    fieldsSource = {};
    // add in the country
    fieldsSource.CountryOfRegistration = [
      {
        source: "CompanySearch",
        status: "verified",
        verifiedOn: lastUpdate,
        locked: true,
      },
    ];
    fieldsSource.RegisteredAddressCountry = [
      {
        source: "CompanySearch",
        status: "verified",
        verifiedOn: lastUpdate,
        locked: true,
      },
    ];

    if (
      isReviewProcess &&
      this.idVerificationService.localFundCreationData.investorFields
    ) {
      $("#LegalPersonCountryOfRegistration").val(
        this.idVerificationService.localFundCreationData.investorFields
          .CountryOfRegistration,
      );
      $("#LegalPersonRegisteredAddressCountry").val(
        this.idVerificationService.localFundCreationData.investorFields
          .CountryOfRegistration,
      );
      returnObject.investorFields.CountryOfRegistration =
        this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration;
      returnObject.investorFields.RegisteredAddressCountry =
        this.idVerificationService.localFundCreationData.investorFields.CountryOfRegistration;
    }
    if(data.content)
    {
      let dataContent = data.content;
      if(dataContent.registrationNumber)
      {
        fieldsSource.RegistrationNumber = [
          {
            source: "CompanySearch",
            status: "verified",
            verifiedOn: lastUpdate,
            locked: true,
          },
        ];
        returnObject.investorFields.RegistrationNumber = dataContent.registrationNumber
        if (isReviewProcess) {
          $("#LegalPersonRegistrationNumber").val(dataContent.registrationNumber);
        }
      }
      if (dataContent.name) {
        returnObject.investorFields.RegisteredName = dataContent.name;
        fieldsSource.RegisteredName = [
          {
            source: "CompanySearch",
            status: "verified",
            verifiedOn: lastUpdate,
            locked: true,
          },
        ];
        if (isReviewProcess) {
          $("#LegalPersonRegisteredName").val(dataContent.name);
        }
      }
      if (dataContent.status) {
        if (dataContent.status === "LIVE") {
          returnObject.investorFields.Status = "ACTIVE";
        } else {
          returnObject.investorFields.Status = "INACTIVE";
        }
        fieldsSource.Status = [
          {
            source: "CompanySearch",
            status: "verified",
            verifiedOn: lastUpdate,
            locked: true,
          },
        ];
      }
      if (dataContent?.additionalData?.contactInformation?.formattedAddress) {
        let formattedAddress = dataContent.additionalData.contactInformation.formattedAddress
        this.idVerificationService.localFundCreationData.formattedAddress = true;
        if (formattedAddress.number) {
          returnObject.investorFields.RegisteredAddressNumber =
            formattedAddress.number;
          fieldsSource.RegisteredAddressNumber = [
            {
              source: "CompanySearch",
              status: "verified",
              verifiedOn: lastUpdate,
              locked: true,
            },
          ];
          if (isReviewProcess) {
            $("#LegalPersonRegisteredAddressNumber").val(
              formattedAddress.number,
            );
          }
        }
        if (formattedAddress.street) {
          returnObject.investorFields.RegisteredAddressStreet =
            formattedAddress.street;
          fieldsSource.RegisteredAddressStreet = [
            {
              source: "CompanySearch",
              status: "verified",
              verifiedOn: lastUpdate,
              locked: true,
            },
          ];
          if (isReviewProcess) {
            $("#LegalPersonRegisteredAddressStreet").val(
              formattedAddress.street,
            );
          }
        }
        if (formattedAddress.city) {
          returnObject.investorFields.RegisteredAddressCity =
            formattedAddress.city;
          fieldsSource.RegisteredAddressCity = [
            {
              source: "CompanySearch",
              status: "verified",
              verifiedOn: lastUpdate,
              locked: true,
            },
          ];
          if (isReviewProcess) {
            $("#LegalPersonRegisteredAddressCity").val(
              formattedAddress.city,
            );
          }
        }
        if (formattedAddress.zip) {
          returnObject.investorFields.RegisteredAddressPostalCode =
            formattedAddress.zip;
          fieldsSource.RegisteredAddressPostalCode = [
            {
              source: "CompanySearch",
              status: "verified",
              verifiedOn: lastUpdate,
              locked: true,
            },
          ];
          if (isReviewProcess) {
            $("#LegalPersonRegisteredAddressPostalCode").val(
              formattedAddress.zip,
            );
          }
        }
      }
    }
    returnObject.fieldsSource = fieldsSource;
  }
}
