import { NgModule, Pipe, PipeTransform } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./layout/layout.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { IntegrityCheckBarComponent } from "./components/integrity-check-bar/integrity-check-bar.component";
import { HeaderSearchComponent } from "./components/header-search/header-search.component";
import { LightboxComponent } from "./lightbox/lightbox.component";
import { LighboxContentComponent } from "./lighbox-content/lighbox-content.component";
import { ContentBuilderComponent } from "./content-builder/content-builder.component";
import { FormsModule } from "@angular/forms";
import {
  NgbModule,
  NgbDateStruct,
  NgbCalendar,
} from "@ng-bootstrap/ng-bootstrap";
import { TextareaAutosizeModule } from "ngx-textarea-autosize";
import { ContentElementsComponent } from "./content-builder/content-elements/content-elements.component";
import { CompanySearchComponent } from "./components/company-search/company-search.component";
import { MyDatePickerComponent } from "./content-builder/content-elements/my-date-picker.component";
import { RouterModule } from "@angular/router";
import { HeaderProjectManagerComponent } from "./components/header-project-manager/header-project-manager.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { IdVerificationResultComponent } from "./components/id-verification-result/id-verification-result.component";
import { IdVerificationProjectListComponent } from "./components/id-verification-project-list/id-verification-project-list.component";
import { IdVerificationProjectLightboxComponent } from "./components/id-verification-project-lightbox/id-verification-project-lightbox.component";
import { ColorPickerModule } from "ngx-color-picker";
import { ContributorInvitationHeaderComponent } from "../id-verification/pages/contributor-invitation/contributor-invitation-header/contributor-invitation-header.component";
import { ShareRequestHeaderComponent } from "../id-verification/pages/share-request/share-request-header/share-request-header.component";
import { DomSanitizer } from "@angular/platform-browser";
import { FormBuilderComponent } from "./form-builder/form-builder.component";
import { FormioModule } from "@formio/angular";
import { DigitalVerificationEvidenceIdVerificationComponent } from "./content-builder/content-elements/components/digital-verification-evidence-id-verification/digital-verification-evidence-id-verification.component";
import { DigitalVerificationEvidenceAddressVerificationComponent } from "./content-builder/content-elements/components/digital-verification-evidence-address-verification/digital-verification-evidence-address-verification.component";
import { DigitalVerificationEvidenceShareLinkComponent } from "./content-builder/content-elements/components/digital-verification-evidence-share-link/digital-verification-evidence-share-link.component";
import { FundsParseEvidenceNoneComponent } from "./content-builder/content-elements/components/funds-parse-evidence-none/funds-parse-evidence-none.component";
import { FundsParseEvidenceSpSideComponent } from "./content-builder/content-elements/components/funds-parse-evidence-sp-side/funds-parse-evidence-sp-side.component";
import { FundsParseEvidenceInvestorSideComponent } from "./content-builder/content-elements/components/funds-parse-evidence-investor-side/funds-parse-evidence-investor-side.component";
import { FundsAddRelatedPartyComponent } from "./content-builder/content-elements/components/funds-add-related-party/funds-add-related-party.component";
import { FundsParseRelatedPartyComponent } from "./content-builder/content-elements/components/funds-parse-related-party/funds-parse-related-party.component";
import { RelatedPartyPillarComponent } from "./content-builder/content-elements/components/funds-parse-related-party/related-party-pillar/related-party-pillar.component";
import { RelatedPartyContentSpSideComponent } from "./content-builder/content-elements/components/funds-parse-related-party/related-party-content-sp-side/related-party-content-sp-side.component";
import { RelatedPartyContentInvestorSideComponent } from "./content-builder/content-elements/components/funds-parse-related-party/related-party-content-investor-side/related-party-content-investor-side.component";
import { PreviewBasicFieldsComponent } from "./content-builder/content-elements/components/preview-basic-fields/preview-basic-fields.component";
import { VerificationStatusesComponent } from "./content-builder/content-elements/components/verification-statuses/verification-statuses.component";
import { EntryNotificationsSpSideComponent } from "./content-builder/content-elements/components/entry-notifications-sp-side/entry-notifications-sp-side.component";
import { FormBuilderHeaderComponent } from "./form-builder/form-builder-header/form-builder-header.component";
import { DigitalVerificationEvidenceContractsComponent } from "./content-builder/content-elements/components/digital-verification-evidence-contracts/digital-verification-evidence-contracts.component";
import { PreviewWindowIdVerificationComponent } from "./content-builder/content-elements/components/preview-window-id-verification/preview-window-id-verification.component";
import { PreviewWindowCddSharingComponent } from "./content-builder/content-elements/components/preview-window-cdd-sharing/preview-window-cdd-sharing.component";
import { PreviewIframeComponent } from "./content-builder/content-elements/components/preview-iframe/preview-iframe.component";
import { PreviewImageComponent } from "./content-builder/content-elements/components/preview-image/preview-image.component";
import { DigitalVerificationEvidenceAPSComponent } from "./content-builder/content-elements/components/digital-verification-evidence-aps/digital-verification-evidence-aps.component";
import { PreviewWindowApsComponent } from "./content-builder/content-elements/components/preview-window-aps/preview-window-aps.component";
import { DigitalVerificationEvidenceShareDialogComponent } from "./content-builder/content-elements/components/digital-verification-evidence-share-dialog/digital-verification-evidence-share-dialog.component";
import { InvestorSelectEntityTypeComponent } from "./content-builder/content-elements/components/investor-select-entity-type/investor-select-entity-type.component";
import { RelatedPartyComponent } from "./content-builder/content-elements/components/investor-select-entity-type/related-party/related-party.component";
import { ParseEvidenceFieldsComponent } from "./content-builder/content-elements/components/parse-evidence-fields/parse-evidence-fields.component";
import { TemplateCreationConfigComponent } from "./content-builder/content-elements/components/template-creation-config/template-creation-config.component";
import { KompanySeachComponent } from "./content-builder/content-elements/components/kompany-seach/kompany-seach.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { IdvUploaderSideAddressVerificationComponent } from "./content-builder/content-elements/components/idv-uploader-side-address-verification/idv-uploader-side-address-verification.component";
import { FormBuilderListOfContractsComponent } from "./form-builder/form-builder-list-of-contracts/form-builder-list-of-contracts.component";
import { FormHeaderComponent } from "./form-builder/form-header/form-header.component";
import { FormBuilderPreviewComponent } from "./form-builder/form-builder-preview/form-builder-preview.component";
import { FormBuilderJsonPreviewComponent } from "./form-builder/form-builder-json-preview/form-builder-json-preview.component";
import { FormOutputsEditorComponent } from "./form-builder/form-outputs-editor/form-outputs-editor.component";
import { QuestionnaireBuilderComponent } from "./questionnaire-builder/questionnaire-builder.component"; 
import { QBuilderHeaderComponent } from "./questionnaire-builder/q-builder-header/q-builder-header.component";
import { QHeaderComponent } from "./questionnaire-builder/q-header/q-header.component";
import { QBuilderListComponent } from "./questionnaire-builder/q-builder-list/q-builder-list.component";
import { QBuilderPreviewComponent } from "./questionnaire-builder/q-builder-preview/q-builder-preview.component";
import { KycTemplateDashboardComponent } from "./kyc-templates/kyc-template-dashboard/kyc-template-dashboard.component";
import { KycTemplatesListComponent } from "./kyc-templates/kyc-template-dashboard/kyc-templates-list/kyc-templates-list/kyc-templates-list.component";
import { ToastNotificationsComponent } from "./toast-notifications/toast-notifications.component";
import { QBuilderJsonPreviewComponent } from "./questionnaire-builder/q-builder-json-preview/q-builder-json-preview.component";
import { ProfilesStatusInformationComponent } from "./content-builder/content-elements/components/profiles-status-information/profiles-status-information.component";
import { RelatedPartyLoadingPillarComponent } from "./content-builder/content-elements/components/related-party-loading-pillar/related-party-loading-pillar.component";
import { LanguageSwitcherComponent } from "./components/language-switcher/language-switcher.component";
import { TooltipComponent } from "./components/tooltip/tooltip-directive/tooltip.component";
import { TooltipDirective } from "./components/tooltip/tooltip-directive/tooltip.directive";
import { TooltipComponentComponent } from "./components/tooltip/tooltip-component/tooltip-component.component";
import { InvalidLinkPageComponent } from "./components/invalid-link-page/invalid-link-page.component";
import { DigitalVerificationEvidenceIdVerificationComponentGo } from "./content-builder/content-elements/components/digital-verification-evidence-id-verification-go/digital-verification-evidence-id-verification-go.component";
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { ToggleButtonComponent } from "./components/toggle-button/toggle-button.component";
import { EvidenceViewBoxComponent } from "./components/evidence-view-box/evidence-view-box.component";
import { ActivityLogComponent } from "./components/activity-log/activity-log.component";
import { AutocompleteInputComponent } from "./components/autocomplete-input/autocomplete-input.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { MultipleSelectComponent } from "./components/multiple-select/multiple-select.component";
import { RiskAssessmentDashboardComponent } from "../id-verification/pages/risk-assessment/risk-assessment-dashboard/risk-assessment-dashboard.component";
import { RiskAssessmentPreviewComponent } from "../id-verification/pages/risk-assessment/risk-assessment-preview/risk-assessment-preview.component";
import { RiskRatingApprovalComponent } from './lightbox/risk-rating-approval/risk-rating-approval.component';
import { RiskIndicatorOverrideComponent } from './lightbox/risk-indicator-override/risk-indicator-override.component'; 
import { RiskRatingAssignContributorComponent } from './lightbox/risk-rating-assign-contributor/risk-rating-assign-contributor.component'; 
import { FullscrenLoadingOverlayComponent } from '../ui/content-builder/content-elements/components/fullscren-loading-overlay/fullscren-loading-overlay.component';
import { ContractVerificationOnboardSubjectComponent } from "../id-verification/pages/contract-verification-onboard-subject/contract-verification-onboard-subject.component";

@Pipe({ name: "safeUrl" })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@Pipe({
  name: "reverse",
})
export class ReversePipe implements PipeTransform {
  transform(arr) {
    if (arr !== undefined) {
      const copy = arr.slice();
      return copy.reverse();
    } else {
      return arr;
    }
  }
}

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    IntegrityCheckBarComponent,
    HeaderSearchComponent,
    LightboxComponent,
    LighboxContentComponent,
    ContentBuilderComponent,
    ContentElementsComponent,
    CompanySearchComponent,
    MyDatePickerComponent,
    HeaderProjectManagerComponent,
    IdVerificationResultComponent,
    IdVerificationProjectListComponent,
    IdVerificationProjectLightboxComponent,
    ContributorInvitationHeaderComponent,
    ShareRequestHeaderComponent,
    SafeUrlPipe,
    ReversePipe,
    FormBuilderComponent,
    KycTemplateDashboardComponent,
    DigitalVerificationEvidenceIdVerificationComponent,
    DigitalVerificationEvidenceIdVerificationComponentGo,
    DigitalVerificationEvidenceAddressVerificationComponent,
    DigitalVerificationEvidenceShareLinkComponent,
    FundsParseEvidenceNoneComponent,
    FundsParseEvidenceSpSideComponent,
    FundsParseEvidenceInvestorSideComponent,
    FundsAddRelatedPartyComponent,
    FundsParseRelatedPartyComponent,
    RelatedPartyPillarComponent,
    RelatedPartyContentSpSideComponent,
    RelatedPartyContentInvestorSideComponent,
    PreviewBasicFieldsComponent,
    EntryNotificationsSpSideComponent,
    FormBuilderHeaderComponent,
    VerificationStatusesComponent,
    DigitalVerificationEvidenceContractsComponent,
    PreviewWindowIdVerificationComponent,
    PreviewWindowCddSharingComponent,
    PreviewIframeComponent,
    PreviewImageComponent,
    DigitalVerificationEvidenceAPSComponent,
    PreviewWindowApsComponent,
    DigitalVerificationEvidenceShareDialogComponent,
    InvestorSelectEntityTypeComponent,
    RelatedPartyComponent,
    ParseEvidenceFieldsComponent,
    TemplateCreationConfigComponent,
    KompanySeachComponent,
    IdvUploaderSideAddressVerificationComponent,
    FormBuilderListOfContractsComponent,
    KycTemplatesListComponent,
    FormHeaderComponent,
    FormBuilderPreviewComponent,
    FormBuilderJsonPreviewComponent,
    FormOutputsEditorComponent,
    QuestionnaireBuilderComponent,
    QBuilderHeaderComponent,
    QHeaderComponent,
    QBuilderListComponent,
    QBuilderPreviewComponent,
    KycTemplateDashboardComponent,
    KycTemplatesListComponent,
    ToastNotificationsComponent,
    QBuilderJsonPreviewComponent,
    ProfilesStatusInformationComponent,
    RelatedPartyLoadingPillarComponent,
    LanguageSwitcherComponent,
    TooltipComponentComponent,
    TooltipComponent,
    TooltipDirective,
    InvalidLinkPageComponent,
    EvidenceViewBoxComponent,
    ToggleButtonComponent,
    ActivityLogComponent,
    AutocompleteInputComponent,
    SidebarComponent,
    MultipleSelectComponent,
    RiskAssessmentDashboardComponent,
    RiskAssessmentPreviewComponent,
    RiskRatingApprovalComponent,
    RiskIndicatorOverrideComponent,
    RiskRatingAssignContributorComponent,
    FullscrenLoadingOverlayComponent,
    ContractVerificationOnboardSubjectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    TextareaAutosizeModule,
    RouterModule,
    AngularSvgIconModule,
    ColorPickerModule,
    FormioModule,
    EditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    LightboxComponent,
    ContentBuilderComponent,
    IdVerificationResultComponent,
    LanguageSwitcherComponent,
    TooltipComponentComponent,
    SidebarComponent,
    MyDatePickerComponent,
    FullscrenLoadingOverlayComponent,
    ContractVerificationOnboardSubjectComponent
  ],
  entryComponents: [LightboxComponent],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
  ],
})
export class UiModule {}
