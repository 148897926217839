/**
 * defines possible VFE's and it also determines the order in which they will be presented on process control screen
 */
export const VFEs = [
  {
    type: "lastRequestedDataVerification",
    name: "Provide basic information about you",
    subTitle: "Please provide some basic information about you",
    subTitleCompleted: "Completed",
    subTitleRejected: "We were not able to verify your basic information",
    method: "startBasicInformation",
    // lazyCondition: `person.record.lastVerification !== undefined
    //                         && person.record.lastVerification.record
    //                         && ((person.record.lastVerification.record.isPartial !== undefined
    //                         && person.record.lastVerification.record.isPartial)
    //                         || person.record.lastVerification.record.status === 'processing' )`,
    // lazyConditionMet: `person.record.lastVerification !== undefined
    //                         && person.record.lastVerification.record
    //                         && (person.record.lastVerification.record.isPartial !== undefined
    //                         && !person.record.lastVerification.record.isPartial)`,
    // lazyDelay: (15 * 60 * 1000),  // delay lazy load for 15 minutes (900000ms)
    vfe: `person.record.lastRequestedDataVerification.key`,
    resolveEndpoint: `api/verifications/{projectKey}/data`,
  },
  {
    type: "lastVerification",
    name: "Verify your identity",
    subTitle: "You will require an identity document to proceed",
    subTitleCompleted: "Completed",
    subTitleRejected:
      "We were not able to verify your identity. You will be contacted with further instructions.",
    method: "startIDVerificationProcess",
    lazyCondition: `person.record.resolveRequiredFor`,
    lazyConditionMet: `person.record.resolveRequiredFor`,
    lazyDelay: 15 * 60 * 1000, // delay lazy load for 15 minutes (900000ms)
    vfe: `person.record.resolveRequiredFor`,
    resolveEndpoint: `api/projects/{projectKey}/people/{personKey}/verifications/identity/{personVfe}/resolve`,
  },
  {
    type: "lastAddressVerification",
    name: "Verify your address",
    subTitle: "You will be required to share your location",
    subTitleCompleted: "Completed",
    subStepsTitle: "Choose how to continue below",
    method: "startAVerificationProcess",
    subStepsLabel: "Please select how you would like to verify your address",
    substeps: [
      {
        id: "standard",
        title: "Standard",
        description: "Available to residents of all countries.",
        clickAction: "startAVerificationProcess",
      },
      {
        id: "idin",
        title: "iDIN",
        description:
          "Available only to residents of The Netherlands or holders of an active Dutch bank account.",
        logo: "/assets/images/idin-logo.svg",
        logoWidth: "35px",
        clickAction: "startIdinVerificationProcess",
      },
    ],
    substepsradio: [
      {
        type: "radiobutton_group",
        params: [
          {
            type: "radio",
            params: {
              id: "phone_call",
              name: "activity",
              label: "Phone Call",
              value: "Phone Call",
            },
          },
          {
            type: "radio",
            params: {
              id: "face_to_face",
              name: "activity",
              label: "Face to face meeting",
              value: "Face to face meeting",
            },
          },
        ],
      },
    ],
    lazyCondition: `person.record.lastAddressVerification !== undefined
                            && person.record.lastAddressVerification.record.relatedIdinVerification !== undefined
                            && person.record.lastAddressVerification.record.status
                            && (person.record.lastAddressVerification.record.status.toUpperCase() === 'IN_PROGRESS'
                            || person.record.lastAddressVerification.record.status.toUpperCase() === 'LAZY_REFRESH'
                            )`,
    // vfe: `person.record.lastAddressVerification.key`,
    lazyDelay: 0,
    vfe: `person.record.lastAddressVerification.record.relatedIdinVerification`, // we need to actually use the relatedIdinVerification key for this one
    resolveEndpoint: `api/projects/{projectKey}/people/{personKey}/verifications/idin/{relatedIdinVerification}/resolve`,
  },
  // iDIN is no longer considered a separate VFE
  // , {
  //     type: 'lastIDin',
  //     substep: true
  // }
  {
    type: "lastContractVerification",
    name: "Complete Document",
    subTitle: "Fill in a form and sign off",
    subTitleCompleted: "Completed",
    method: "startContractSigningProcess",
  },
  {
    type: "lastApsVerification",
    automaticExecution: true, // pill will not be displayed for this step and it will be auto executed when it becomes the next pill
  },
];
